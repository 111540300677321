.store-page{
    background-color: #f0f2f5;
    padding: 30px;
}
.checkout-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.checkout-div button{
    color:white ;
    padding: 10px 30px;
    background: rgb(0, 56, 74);
    border-radius: 10px;
}
.filterx{
    display: flex;
    gap:20px;
    margin-top: 40px;
}
.filterx select{
    padding: 10px;
    border:1px solid gainsboro;
    color:black;
    border-radius: 7px;
}
.store-items{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}
.store-items .s-item{
    width: calc(33% - 20px);
}
.store-items .s-item img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
}
.store-items .s-item .iname{
    font-weight: 600;
    font-size: 1.3em;
}
.store-items .s-item .iprice{
    font-weight: 600;
    color:#1890ff;
    font-size: 1.6em;
    margin-top: 10px;
}
.store-items .s-item .irate{
    color:orange;
    margin-bottom: 10px;
}
.store-items .bbt{
    display: flex;
    justify-content: space-between;
}
.store-items .s-item .acart{
    color:white ;
    padding: 10px 30px;
    background: rgb(0, 56, 74);
    border-radius: 10px;
}
.store-items .s-item .details{
    color:black;
}
.store-details{
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.store-details .inner{
    padding: 15px;
    width:800px;
    border-radius: 10px;
    border: 1px solid gainsboro;
    background-color: white;
    gap: 30px;
    display: flex !important;
}
.store-details .inner  > div{
width:50%;
}
.store-details .inner  .checkout{
width:100% !important;
max-height: 500px;
overflow: auto;
}
.store-details .inner  table td img{
width:100px;
height: 100px;
object-fit: cover;
border-radius: 10px;
}
.store-details .inner  .checkout .ctitle{
display: flex;
justify-content: space-between;
align-items: center;
}
.store-details .inner  .checkout .ctitle > div{
font-size: 20px;
font-weight: 600;
}
.store-details .inner  > div:first-child .mainimg {
width:100%;
}
.store-details .inner  > div:first-child .similar {
width:100%;
display: flex;
gap: 10px;
}
.store-details .inner  > div:first-child .similar a {
width:calc(33.3% - 10px);
}
.store-details .inner  > div:first-child .similar a img {
width:100%;
}
.store-details .inner  > div:first-child .copy{
width:100%;
display: flex;
margin: 10px 0;
align-items: center;
gap: 10px;
}
.store-details .inner  > div:first-child .copy div{
font-size: 0.9em;
}
.store-details .inner  > div:first-child .copy button{
padding: 5px 10px;
border-radius: 5px;
color: black;
border:1px solid gainsboro;
background: #fbfbfb;
font-size: 0.7em;
}
.store-details .inner  > div:first-child .review{
margin-top: 15px;
}
.store-details .inner  > div:first-child .review button{
padding: 8px 10px;
width: 100%;
border-radius: 5px;
color: black;
border:1px solid rgb(67, 119, 198);
background: #fbfbfb;
font-size: 1em;
text-align: center;
}
.store-details .inner  > div:last-child .breadcrumbx{
display: flex;
justify-content: space-between;
align-items: center;
}
.store-details .inner  > div:last-child .breadcrumbx > div:first-child{
color:rgb(113, 172, 255);
font-size: 0.7em;
}
.store-details .inner  > div:last-child .breadcrumbx > div:last-child{
color:grey;
font-size: 1.2em;
}
.store-details .inner  > div:last-child .title{
color:black;
font-weight: 700;
font-size: 1.4em;
}
.store-details .inner  > div:last-child .price-review{
margin-top: 15px;
padding-bottom: 15px;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}
.store-details .inner  > div:last-child .price-review > div:first-child{
font-size: 1.2em;
}
.store-details .inner  > div:last-child .price-review > div:nth-child(2){
color: orange;
}
.store-details .inner  > div:last-child .desc{
border-bottom: 1px solid gainsboro;
border-top: 1px solid gainsboro;
padding: 15px 0;
}
.store-details .inner  > div:last-child .option{
display: flex;
align-items: center;
justify-content: space-between;
padding: 15px 0;
}
.store-details .inner  > div:last-child .option select{
padding: 5px 10px;
border:1px solid gainsboro;
color: black;
border-radius: 5px;
}
.store-details .inner  > div:last-child .option > div{
font-size: 0.7em;
}
.store-details .inner  > div:last-child .cart{
padding: 10px 0;
}
.store-details .inner  > div:last-child .cart button{
padding: 10px 50px;
color: white;
border:0;
background: rgb(38, 38, 111);
}
