.store-page{
    background-color: #f0f2f5;
    padding: 30px;
}
.checkout-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.checkout-div button{
    color:white ;
    padding: 10px 30px;
    background: rgb(0, 56, 74);
    border-radius: 10px;
}
.filterx{
    display: flex;
    gap:20px;
    margin-top: 40px;
}
.filterx select{
    padding: 10px;
    border:1px solid gainsboro;
    color:black;
    border-radius: 7px;
}
.store-items{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}
.store-items .s-item{
    width: calc(33% - 20px);
}
.store-items .s-item img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
}
.store-items .s-item .iname{
    font-weight: 600;
    font-size: 1.3em;
}
.store-items .s-item .iprice{
    font-weight: 600;
    color:#1890ff;
    font-size: 1.6em;
    margin-top: 10px;
}
.store-items .s-item .irate{
    color:orange;
    margin-bottom: 10px;
}
.store-items .bbt{
    display: flex;
    justify-content: space-between;
}
.store-items .s-item .acart{
    color:white ;
    padding: 10px 30px;
    background: rgb(0, 56, 74);
    border-radius: 10px;
}
.store-items .s-item .details{
    color:black;
}
.store-details{
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.store-details .inner{
    padding: 15px;
    width:800px;
    border-radius: 10px;
    border: 1px solid gainsboro;
    background-color: white;
    display: block !important;
}
.store-details .inner input{
    padding: 15px;
    width:100%;
    margin-top:10px;
    border:1px solid gainsboro;
    display: block;
}
.store-details .inner button{
   margin-top: 10px;
   padding: 10px;
   background: rgb(0, 0, 68);
   color: white;

}
.uimages{
    display:flex;
    gap:20px;
}
.uimages img{
   width:100px;
}
