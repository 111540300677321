/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  /* background: url('https://getwall.net/wp-content/uploads/2019/04/Light-desktop-hd-wallpapers-768x480.jpg') no-repeat center center fixed; */
  /* background: url('./img/overlay.jpg') no-repeat center center fixed ; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: "100%";
  height: 100vh;
  /* background-color: blue; */
}
.logout-btn {
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 2rem;
  margin-right: 6px;
  background-color: #2593ee;
  color: white;
  outline-style: none;
  border-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1d6dae;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1d6dae;
}
progress::-webkit-progress-bar {
  background-color: #f0f0f0;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #4caf50;
  border-radius: 10px;
}