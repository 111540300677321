/* Settings Panel */



$settings-panel-width: 540px;
$settings-panel-width-small: 340px;
$white: #ffffff;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
$black: #000;

.infinite-spin {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes fadeInUp {
from {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  transform: none;
}
}

.fadeInUp {
animation-name: fadeInUp;
}


.side-panel{
  display: block;
  position: fixed;
  top:0;
  right: -$settings-panel-width;
  bottom: 0;
  width: $settings-panel-width;
  height: 100vh;
  min-height: 100%;
  background: $white;
  transition-duration: $action-transition-duration;

 transition-timing-function:$action-transition-timing-function;
 transition-property: right, box-shadow;

  // @include transition-duration($action-transition-duration);
  // @include transition-timing-function($action-transition-timing-function);
  // @include transition-property(right, box-shadow);
  z-index: 9999;
  @media (max-width: 991px) {
    width: $settings-panel-width-small;
  }
  
  &.open{
    right:0;
    -webkit-box-shadow: 7px 0px 80px -9px rgba(0,0,0,0.15);
    -moz-box-shadow: 7px 0px 80px -9px rgba(0,0,0,0.15);
    box-shadow: 7px 0px 80px -9px rgba(0,0,0,0.15);
  }
  .settings-close{
    position: absolute;
    top:10px;
    right: 10px;
    font-size: 28px;
    color: $white;
    background: transparent;
    border-radius:4px;
    // @include border-radius(4px);
    padding: 0 3px;
    cursor: pointer;
    transition-duration:0.2s;
    z-index: 999;
    &:hover{
      background: rgba($white,0.3);
    }
    .rtl & {
      right: unset;
      left: 10px;
    }
  }
  .settings-close-1{
    position: absolute;
    font-size: 28px;
    top:10px;
    right: 10px;
    color: $black;
    background: transparent;
    // @include border-radius(4px);
    border-radius:4px;
    padding: 0 3px;
    cursor: pointer;
    // @include transition-duration(0.2s);
    transition-duration:0.2s;
    z-index: 999;
    &:hover{
      background: rgba($black,0.3);
    }
    .rtl & {
      right: unset;
      left: 10px;
    }
  }
  .rtl & {
    right: unset;
    left: -$settings-panel-width;
    // @include transition-property(left);
    transition-property:left;
    .chat-list{
      padding-right: 0;
      .list{
        .profile{
          margin-right: 0;
          margin-left: 1rem;
        }
        .info{
          margin-right: 0;
          margin-left: auto;
          .badge {
            margin-right: 10px;
          }
        }
      }
    }
    &.open{
      left: 0;
      right: unset;
    }
  }
}

// #theme-settings{
//   .settings-close{
//     top: 12px;
//     @extend .bg-gradient-primary;
//   }
// }

#settings-trigger{
  position: fixed;
  bottom: 40px;
  right: 30px;
  height: 45px;
  width: 45px;
  background: theme-color(primary);
  z-index: 99;
  -webkit-box-shadow: 0px 0px 31px 6px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 0px 31px 6px rgba(0,0,0,0.17);
  box-shadow: 0px 0px 31px 6px #0000002b;
  display:flex;
  align-items:center;
  justify-content:center;
border-radius: 100%;
  @extend .fadeInUp;
  animation-duration:1s;
  animation-delay:1s;
 animation-fill-mode:both;
  // @include display-flex;
  // @include align-items(center);
  // @include justify-content(center);
  // @include border-radius(100%);
  // @extend .fadeInUp;
  // @include animation-duration(1s);
  // @include animation-delay(1s);
  // @include animation-fill-mode(both);
  i{
    color: $white;
    font-size: 1rem;
    line-height: 1rem;
    @extend .infinite-spin;
  }
  @media (max-width: 991px){
    display: none;
  }
}