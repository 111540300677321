@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-primary-btn {
    @apply bg-[#1D6DAE] text-white px-3 py-2 rounded-lg hover:translate-x-1 hover:shadow-md hover:shadow-[#1D6DAE] transition-all;
  }
  .custom-text-field {
    @apply w-10/12 px-2 py-2.5 font-semibold bg-white text-gray-600 outline-[#1D6DAE] rounded-lg hover:shadow-lg;
  }
  .custom-img-upload {
    @apply flex items-center cursor-pointer h-10 mx-auto text-[#1D6DAE] px-2 py-1 mt-3 hover:bg-[#1D6DAE] hover:rounded-md hover:text-white;
  }
  .menu-item {
    @apply hover:bg-[#1D6DAE] hover:text-white transition-all duration-200 cursor-pointer;
  }
}

.App {
  text-align: center;
}

.bg {
  /* background-image: url('./img/bg1.jpg');   no-repeat center center fixed */
  background: url("./img/bg.png") no-repeat center center fixed;
  background-size: cover;
  height: 790px;
  /* width: 100%; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .table-container {
  background-color: #999;
}

.table {
  background-color: pink;
}

.thead {
  background-color: orange;
}

.tr {
  background-color: #fff;
} */

.tr > td {
  text-align: center;
}

.th {
  background-color: #1D6DAE;
  color: white
}
._th {
  background-color: #1D6DAE;
  color: white
}

.th:nth-child(1) {
  width: 40%;
}
.th:nth-child(2) {
  width: 40%;
}
.th:nth-child(3) {
  width: 10%;
  text-align: center;
}

._th:nth-child(1) {
  width: 90%;
}
._th:nth-child(2) {
  width: 10%;
  text-align: center;
}

tr > td:nth-child(3) {
  text-align: center;
}
._tr > ._td:nth-child(2) {
  text-align: center;
}

.tbody {
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  .ind-bg {
    width: 100%;
    height: 620px;
  }
  .i {
    background-color: #ffffff;
    width: 100%;
  }
}
@media screen and (min-width: 991px) {
  .ind-bg {
    width: 50%;
    height: 100vh;
  }
  .i {
    background-color: #ffffff;
    width: 50%;
    height: 100vh;
    padding-bottom: 2rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Login Button */
.submit2 {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  color: #fff;
  margin-top: 10px;
  text-align: center;
  /* 53a6e7 linear-gradient(268.61deg, #1A1CF8 27.36%, #0EB3F3 77.37%) */
  background-color: #53a6e7;
  border: none;
  /* opacity: 1; */
}
.submit2:hover {
  background-color: white;
  color: #180a39;
  border: 1px solid #180a392c;
}
.top {
  margin-top: 16rem;
}
.bg-built {
  background-color: white;
  text-align: center;
  padding: 3.8rem 2rem;
  box-shadow: 10px 18px 16px #180a391a;
  margin-top: 4rem;
}
.b1 {
  font-size: 40px;
  font-weight: 700;
}
.b2 {
  font-size: 20px;
}
.jumb {
  font-weight: 600;
}
.btn_submit1 {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  color: #180a39;
  margin-top: 5px;
  text-align: center;
  /* 53a6e7 linear-gradient(268.61deg, #1A1CF8 27.36%, #0EB3F3 77.37%) */
  background-color: #fff;
  border-color: #180a392c;
  /* opacity: 1; */
}
.btn_submit1:hover {
  background-color: #53a6e7;
  border: none;
}
/* individual */

.ind-bg {
  background: url("./img/bg-signup.png");
  background-size: cover;
}
.in {
  border: 1px solid #282c342c;
  padding: 5px;
  text-align: center;
  border-radius: 50px;
  width: 198px;
  align-items: center;
}
.in-1 {
  background-color: #2593ee;
  border-radius: 50px;
  padding: 0.6rem 0.9rem;
  color: white;
}
.in-3 {
  color: #282c34;
}
.in-3:hover {
  color: #2593ee;
  text-decoration: none;
}

.o {
  background-color: #d5d5d5;
}
.i-1 {
  font-weight: 700;
}

.s-02 {
  font-size: 24px;
  margin-bottom: 3rem;
}
.s-2 {
  font-size: 16px;
}
.s-1 {
  font-size: 48px;
  color: white;
  font-weight: 700;
}
.s-01 {
  color: white;
}
.b-1 {
  display: flex;
}
.free {
  color: #1d6dae;
}
.home-font {
  font-family: "quicksand";
}

/* sidebar */
.sidebar {
  height: auto;
  /* border-left: 0.5px solid grey;
  border-color: rgb(0,0,0,0.1); */
  width: 100%;

  /* opacity: 0.6; */
}

.sid {
  border-right: 0.5px solid grey;
  border-color: rgb(0, 0, 0, 0.1);
  height: 100vh;
}

.terms {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40%;
}
.text {
  height: "60%";
  width: "100%";
  text-align: justify;
  padding: 15px;
  /* box-shadow: 1px 1px 0px 1px rgb(0,0,0,0.5); */
  border: 1px solid #e9e9e9;
  font-family: "quicksand";
  font-size: 15px;
  border-radius: 6px;
  text-align: justify;
  opacity: 0.8;
}

/* profile image upload */
.image-upload > input {
  display: none;
}

.image {
  background: url("img/home_image.png") no-repeat center center;
  background-size: cover;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  object-fit: cover;

  /* height: 65%; */
}

.overlay {
  background-color: #282c34;
  opacity: 0.5;
  height: auto;
  border-radius: 4px;
}

/* eas service image */
.eas-image {
  background-size: center center cover;
  width: 80%;
  height: 40%;
}
.flat {
  display: flex;
  flex-direction: row;
}
/* footer */
.footer {
  height: 40px;
  background-color: black;
  color: white;
  font-family: quicksand;
  font-size: small;
  font-style: normal;
}
.corp-act-page:hover {
  box-shadow: 1px 5px 5px 3px grey;
}
.corp-act-page-border {
  border: 1px #e68723;
}

.unselected {
  color: red;
}

.school-faculty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.schoolDivider {
  border-width: .5px;
  border-color: #1D6DAE;
}

.aboutSchool {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.schoolUCBI {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activateContainer {
  display: relative;
  
}

.activationModal {
  position: absolute;
  /* margin: -calc(width); */
  border: #282c34;
  border-width: 2;
  z-index: 10;
}

.closeRemoveAssociate {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 200;
}

.removeAssociateModal {
  border-width: 2px;
  border-color: #1D6DAE;
  border-style: solid;
  border-radius: 10px;
  overflow: hidden;
  
}
.adminCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 100%;
  background-color: #1D6DAE;
  color: white;
  
}

.paperContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  
}
.selectPlaceholder {
  color: #999;
  font-size: 14px;
  
}


.viewConfirmationContainer {
  display: flex;
  flex-direction: column;
  
  width: 25%;
  float: right;
}

.reAssignButton, .removeButton{
  margin-right: 10px;
  margin-top: 10px;
  outline: inherit;
}

.actionButton:after {
  content: '\2807';
  font-size: 20px;
}

.biCircle{
  color: #1D6DAE;
}

select {
  /* -webkit-appearance: none;
  appearance: none; */
  padding: 0;
  margin: 0
}

select.mySelect {
  padding:10px ;
}
.store-page{
  background-color: #f0f2f5;
  width: 100%;
}
/* .select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 0px;
  position: absolute;
}  */

