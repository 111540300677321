#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.logo {
  height: 90px;
  justify-self: center;
  background-image: url("../img/logoo.png");
  background-size: 80px;
  background-repeat: no-repeat;
  padding-top: 2rem;
}
.profile {
  height: 100%;
  /* justify-self: center; */
  /* background-image: url("../svg/profile-img.svg"); */
  /* background-size: 120px; */
  background-repeat: no-repeat;
  /* padding-top: 2rem; */
}
.site-layout .site-layout-background {
  background: #fff;
}
.f-2 {
  color: white;
  font-size: 16px;
  margin-top: 1rem;
}
.intro {
  background-color: #1d6dae;
  background-image: url("../img/bg-1.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  color: white;
  padding: 2.3rem 2.5rem 3rem;
}
.menu-icon {
  background-image: url("../svg/Home.svg");
  background-repeat: no-repeat;
}
.h-intro {
  color: white;
  font-weight: 800;
  font-size: 36px;
  line-height: 2.9rem;
}
.p-intro {
  font-size: 16px;
  color: white;
}
.sub {
  background-color: white;
  padding: 3rem;
}
.icon {
  margin: 0 12px 0 0;
  padding-top: 10px;
  float: "left";
}
.layout {
  height: 100vh;
}
.menu-icon {
  height: 48px;
  width: 48px;
}
.sidebar-bg {
  background: #1d6dae;
}
.sub-body {
  background-color: white;
  background-image: url("../img/bg-2.png");
  background-repeat: no-repeat;
  background-position: 100% 60%;
}
.sub-body1 {
  background-color: white;
}
.sub-but {
  background-color: #1890ff;
  border-radius: 0;
  height: 2.8rem;
  border: 0;
}
.sub-but:hover {
  background-color: #3ca1ff;
  border: 0;
}
.nav-but {
  margin-top: 0.8rem;
}
.form-1 {
  border-radius: 0;
  height: 3.4rem;
}
.sub-img {
  background-image: url("../img/slides/Slide1.PNG");
  height: 250px;
  background-size: cover;
}
.font-3 {
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.sub-item {
  padding: 2rem;
}
.account {
  margin-top: -0.6rem;
  font-size: 16px;
  font-weight: 700;
}
.font-2 {
  font-size: 16px;
}
.text-bold {
  font-weight: 700;
  font-size: 24px;
  line-height: 2rem;
}
.menu-img {
  background-image: url("../svg/Home.svg");
}
/* .font-2 {
    font-size: 16px;
  } */
.bg-2 {
  padding: 2rem;
  border: 1px solid rgb(211, 211, 211);
}
.sub-but1 {
  background: #b4b4b4;
  border-radius: 0;
  height: 2.8rem;
  border: 0;
}

.head {
  background: #fff;
}
.head h3 {
  font-size: 26px;
  font-weight: 700;
}
.a-1 {
  padding: 2rem;
}
.rm-body {
  background-color: #fff;
  padding: 2rem;
}

.tab-bottom {
  position: relative;
  width: 50%;
  bottom: 0px;
}

.sidebar {
  display: none;
}

.hideOnMobile {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hideOnMobile {
    display: block;
  }
}
.i .hideOnDesktop {
  display: block;
}
@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

.addBtn {
  /* background-color: ; */
  justify-self: end;
}

.o {
  background-color: #b4b4b4;
}
.i {
  background-color: #ffffff;
}
.color1 {
  font-weight: 400;
  color: #5d5d5d;
}
.btn-success1 {
  background-color: #1d6dae;
  color: white;
}
.bg-success1 {
  background-color: #1d6dae;
}

.tab-but {
  margin-top: -4.9rem;
  padding-left: 2.4rem;
}
.tab1 {
  padding-left: -1rem;
}

.always-ontop {
  z-index: 1000;
}

/* list  */

/* li1 {
  background: url("/public/li1.GIF") 50% 50% no-repeat no-repeat;
  padding-left: 5px;
} */
